@import '~antd/dist/antd.less';
@import '~antd/lib/style/themes/default.less';
@import './styles/colors.less';

/* Variables */
@header-height: 48px;
@layout-margin: 8px;

.site-layout {
  margin-top: @header-height;
}

.site-layout-background {
  height: calc(100vh - (@header-height + 2 * @layout-margin));
  overflow: hidden;
}

.ant-layout-header {
  position: fixed;
  z-index: 1;
  width: 100%;
  background: white;
  height: @header-height;
  line-height: @header-height;
  padding: 0 12px;
}

.header-menu {
  display: flex;
}

.product-switch-btn {
  position: absolute;
  top: 8px;
  left: 47vw;
}

.brand-logo {
  padding: 6px 0 0 16px;
}

.spin-center {
  width: 100%;
}

.infinite-scroll-view {
  height: 1px;
}

.other-reason-error {
  margin-top: -15px;
  display: block;
}

.nav-bar {
  display: flex;
  padding: 0;
  margin-left: 50px;

  a.nav {
    margin-right: 35px;
    color: @grey-7;

    .anticon {
      margin-right: @layout-margin;
    }
  }
}

.nav-bar.active .nav {
  border-bottom: 2px solid @blue-6;
  color: @blue-6;
}

@primary-color: #FC024D;@error-color: #E35F35;